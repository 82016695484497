<template>
  <div>
     <iframe id="iframeVisor360"  class="iframe-visor" ref="iframeVisor360" frameborder="0"></iframe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            loaded: false
        }
    },
    computed: {
        ...mapGetters({
            lang: "getLanguage",
            park: "getCurrentParkByDomain"
        })
    },
    async mounted() {
        try {
            document.querySelector(".v-main__wrap").style.overflow = "hidden";
    
            this.$store.commit("activeLoader", true);
            
            const { pin, id360 } = this.$route.params;
            
            const html = await this.$store.dispatch("fetchVisor360", {
                pinId: pin,
                galleryId: id360
            })
    
            if(!html) {
                this.$store.commit("activeLoader", false);
                return
            };
    
            const doc = document.getElementById('iframeVisor360').setAttribute("src", 
            `${process.env.VUE_APP_API_URL_PIDE}/?accion=consultarVisor360&IDIOMA=${this.lang}&IDPARQUE=${this.park.IDPARQUE}&IDPIN=${pin}&IDGALERIA=${id360}`
            )
            this.$store.commit("activeLoader", false);
        } catch (error) {
            
        }

    },
    beforeDestroy() {
        document.querySelector(".v-main__wrap").style.overflow = "auto";
    }
}
</script>

<style lang="scss">
.iframe-visor {
    width: 100%;
    height: calc(100vh - 85px);
    @media screen and (max-width: 600px) {
        height: calc(100vh - calc(85px + 42px));
    }
    @media screen and (max-width: 500px) {
        height: calc(100vh - calc(61px + 42px));
    }
}
</style>